<template>
    <v-navigation-drawer :mini-variant.sync="mini" permanent app clipped>
        <v-list>
            <v-list-item
                link
                :to="link.to"
                v-for="link in menu"
                :key="JSON.stringify(link)"
                color="primary"
                exact
            >
                <v-list-item-icon>
                    <v-icon color="#232020">{{ link.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title class="subtitle-1">{{
                        $t(link.text)
                    }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import menu from "./menu";

export default {
    name: "UserDrawerMenu",
    props: ["mini"],
    data() {
        return {
            drawer: false,
            menu: menu
        };
    },
    mounted() {
        console.log(`id user: ${this.$auth.user().id}`);
    }
};
</script>
<style>
.subtitle-1 {
    color: #232020;
}
</style>
