<template>
    <div class="d-flex flex-grow-1">
        <top-menu @toggleDrawer="mini = !mini" :is-dashboard="true" />
        <user-drawer-menu :mini="mini" />
        <v-main :style="{ background: '#F3F1F1' }">
            <v-container>
                <slot> <!--CONTENT--> </slot>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import UserDrawerMenu from "../components/dashboard/UserDrawerMenu";
import TopMenu from "../components/TopMenu";
import Settings from "@/models/Settings";
import {mapActions} from "vuex";
export default {
    name: "user-dashboard-layout",
    components: { TopMenu, UserDrawerMenu },
    data: function() {
        return {
            mini: false,
            shop_settings: [],
        };
    },
    methods: {
        ...mapActions(['fillSettings']),
    },
    async mounted() {
        this.shop_settings = await Settings.get()
        this.fillSettings(this.shop_settings);
    }
};
</script>

<style scoped></style>
