<template>
    <v-dialog v-model="orderShowDialog" max-width="750px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ $t('general.order') }}: {{ order.id }}</span>
            </v-card-title>

            <v-container>
                <v-row>
                    <v-col cols="12" class="pb-0 pt-0">
                        <v-card-subtitle>{{ $t('general.customer-name') }}</v-card-subtitle>
                        <v-card-text>{{ order.name }}</v-card-text>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="pb-0 pt-0">
                        <v-card-subtitle>{{ $t('general.details') }}</v-card-subtitle>
                        <v-card-text :style="{ whiteSpace: 'pre-line' }">{{ order.details }}</v-card-text>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="pb-0 pt-0">
                        <v-card-subtitle>{{ $t('general.payment_method') }}</v-card-subtitle>
                        <v-card-text>{{ order.payment_method }}</v-card-text>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" class="pb-0 pt-0">
                        <v-card-subtitle>{{ $t('general.customer-email') }}</v-card-subtitle>
                        <v-card-text>{{ order.email }}</v-card-text>
                    </v-col>
                    <v-col cols="6" class="pb-0 pt-0">
                        <v-card-subtitle>{{ $t('general.customer-phone') }}</v-card-subtitle>
                        <v-card-text>{{ order.phone }}</v-card-text>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" class="pb-0 pt-0">
                        <v-card-subtitle>{{ $t('general.delivery-time') }}</v-card-subtitle>
                        <v-card-text>{{ order.time }}</v-card-text>
                    </v-col>
                    <v-col cols="6" class="pb-0 pt-0">
                        <v-card-subtitle>{{ $t('general.delivery-date') }}</v-card-subtitle>
                        <v-card-text>{{ order.date }}</v-card-text>
                    </v-col>
                </v-row>
                <v-row v-if="order.delivery">
                    <v-col cols="3" class="pb-0 pt-0" v-if="order.delivery.address">
                        <v-card-subtitle>Address</v-card-subtitle>
                        <v-card-text>{{ order.delivery.address }}</v-card-text>
                    </v-col>
                    <v-col cols="3" class="pb-0 pt-0" v-if="order.delivery.zip">
                        <v-card-subtitle>Zip</v-card-subtitle>
                        <v-card-text>{{ order.delivery.zip }}</v-card-text>
                    </v-col>
                    <v-col cols="3" class="pb-0 pt-0" v-if="order.delivery.vat_number">
                        <v-card-subtitle>VAT number</v-card-subtitle>
                        <v-card-text>{{ order.delivery.vat_number }}</v-card-text>
                    </v-col>
                    <v-col cols="3" class="pb-0 pt-0" v-if="order.delivery.info">
                        <v-card-subtitle>Info</v-card-subtitle>
                        <v-card-text>{{ order.delivery.info }}</v-card-text>
                    </v-col>
                </v-row>
                <v-row v-if="order.delivery">
                    <v-col cols="4" class="pb-0 pt-0" v-if="order.delivery_cost">
                        <v-card-subtitle>Delivery cost</v-card-subtitle>
                        <v-card-text>{{ order.delivery_cost }}</v-card-text>
                    </v-col>
                </v-row>
                <v-row v-if="cart.cart">
                    <v-col cols="12" class="pb-0">
                        <div class="d-block mb-3 pa-3">
                            <v-list
                                v-for="item in JSON.parse(cart.cart.cart_list)"
                                :key="item.id_product"
                                :title="item.name"
                                :subtitle="item.variation_name"
                            >
                                <div class="show-order__card">
                                    <div class="show-order__wrap-img">
                                        <img :src="item.image" alt="item.name">
                                    </div>
                                    <div class="show-order__data">
                                        <div><span class="text-bold">{{ $t('general.title') }}:</span> {{ item.name }}
                                        </div>
                                        <div><span class="text-bold">{{ $t('general.variation') }}:</span>
                                            {{ item.variation_name }}
                                        </div>
                                        <div><span class="text-bold">{{ $t('general.qty') }}:</span> {{
                                                item.qty_product
                                            }}
                                        </div>
                                        <div><span class="text-bold">{{ $t('general.price') }}:</span> {{ item.price }}
                                        </div>
                                    </div>
                                </div>

                            </v-list>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="pt-0">
                        <v-card-subtitle class="pt-0">{{ $t('general.amount') }} :</v-card-subtitle>
                        <v-card-text>{{ order.amount }}</v-card-text>
                    </v-col>
                </v-row>
            </v-container>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="hideDialog"
                >
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "OrderShowDialogPopup",
    props: ['order', 'orderShowDialog', 'cart'],
    data: () => ({}),
    methods: {
        hideDialog() {
            this.orderShowDialog = false;
            this.$emit('hideOrderShowDialog');
        }
    },
    watch: {
        order(newData) {
            if (this.orderShowDialog) this.order = newData
        }
    }
}
</script>

<style scoped>
.show-order__wrap-img {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 10px
}

.show-order__wrap-img img {
    max-width: 100%;
}

.show-order__card {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
}

.show-order__data {
    margin-left: 20px;
    font-size: 14px;
}

.show-order__data .text-bold {
    font-weight: bold;
}
</style>
