export default [
    {
        to: {name: "dashboard.index"},
        icon: "mdi-newspaper-variant-multiple-outline",
        text: 'general.dashboard',
    },
    {
        to: {name: "dashboard.schedule.index"},
        icon: "mdi-calendar-clock",
        text: 'general.schedule'
    },
    {
        to: {name: "dashboard.widget_settings.index"},
        icon: "mdi-cog-outline",
        text: 'general.widget-settings',
    },
    {
        to: {name: "dashboard.shop_settings.index"},
        icon: "mdi-store",
        text: 'general.shop-settings',
    },
    {
        to: {name: "dashboard.orders.index"},
        icon: "mdi-order-bool-ascending",
        text: 'general.orders',
    },
    {
        to: {name: "dashboard.products.index"},
        icon: "mdi-basket-plus-outline",
        text: 'general.products',
    },
    {
        to: {name: "dashboard.categories.index"},
        icon: "mdi-page-next-outline",
        text: 'general.categories',
    },
    {
        to: {name: "dashboard.templates.index"},
        icon: "mdi-email-outline",
        text: 'general.templates',
    },
    {
        to: {name: "dashboard.notifications.index"},
        icon: "mdi-bell-outline",
        text: 'general.notifications',
    },
    {
        to: {name: "dashboard.holiday.index"},
        icon: "mdi-calendar-outline",
        text: 'general.holiday',
    }
];
