import Echo from 'laravel-echo';

let echoInstance = null;

/**
 * Return the Echo instance
 * @param token
 * @return {Echo}
 */
export const getEchoInstance = (token) => {
    if (!echoInstance) {
        echoInstance = new Echo({
            broadcaster: 'pusher',
            key: process.env.VUE_APP_PUSHER_APP_KEY,
            cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
            forceTLS: true,
            auth: {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        });
    }
    return echoInstance;
};
